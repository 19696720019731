.prompt {
}

.user-info-container {

}

.user-name {
  color:#39be2b;
}

.host-name {
  color: #39be2b;
}

.working-dir {
  color: #d43bd2;
}

.user-input-container {

}

.user-input {
  width: 1ch;
  color: #fafafa;

  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;

  border: none;
  outline: none;
  background-color: transparent;
}

.breathing-space {
  margin-bottom: 2rem;
}

.blinker {
  display: inline-block;

  width: 0.25rem;
  height: 1rem;

  color: #fafafa;
  background: #fafafa;

  animation: cursor-blink 1s steps(2) infinite;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
