.about {

}

.about-p {
  max-width: 80ch;
  white-space: normal !important;
}

.about-no-margin {
  margin: 0;
}

.projects {
  display: flex;

  flex-direction: row;
  
  height: 100%;
}

.projects-description {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.projects-p {
  margin: 0;
}

.thin-text {
  font-style: italic;
}

.writings {
  display: flex;

  flex-direction: row;
  
  height: 100%;
}

.writings-p {
  margin: 0;
}

.welcome {
  width: min-content
}

.cool-light-blue {
  color: #66B7E8;
}

.cool-blue {
  color: #009DDC;
}

.cool-dark-blue {
  color: #007AB8;
}

.cool-yellow {
  color: #FDB827;
}

.cool-orange {
  color: #F5821F;
}

.cool-purple {
  color: #963D97;
}

.cool-green {
  color:#61BB46;
}

.cool-magenta {
  color: #d43bd2;
}

.cool-red {
  color: #E03A3E;
}

.cool-discord {
  color: #7289da;
}

.cool-twitter {
  color: #1DA1F2;
}

.cool-github {
  color: #6cc644;
}

.cool-mastadon {
  color: #6059f2;
}

.cool-mail {
  color: #ea4335;
}
