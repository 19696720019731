.launchpad {
  color: #fafafa;

  height: 95%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.launchpad-canvas {
  position: absolute;
  top: 0;

  z-index: -1;

  background-repeat: no-repeat;

  opacity: 0;

  -webkit-transition: opacity 2.0s ease-in-out;
  transition: opacity 2.0s ease-in-out;
}

.launchpad-h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

.launchpad-grid {
  display: flex;
  flex-direction: row;

  gap: 4rem;
  padding: 1rem 2rem 1rem 2rem;

  border-radius: 16px;
  background: rgba(33, 33, 33, 0.25);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(80, 80, 80, 0.25);

  justify-content: center;
}

.launchpad-li {
  list-style: none;
}

.launchpad-li:hover {
  cursor: pointer;
}

.launchpad-app {
  display: flex;
  flex-direction: column;

  align-items: center;

  transition: all 0.1s ease-in-out;
}

.launchpad-app:hover {
  transform: scale(1.15);
}

.launchpad-h3 {
  font-size: 0.75rem;
}

.launchpad-icon {
  width: 72px;

  -webkit-box-reflect: below 2px
  -webkit-gradient(linear, left top, left bottom, from(transparent),
    color-stop(0.9, transparent), to(rgba(255,255,255,.5)));
}

.launchpad-footer {
  position: absolute;
  bottom: 0.25rem;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.launchpad-signature {
  width: 10rem;

  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.launchpad-footer-hr {
  width: 90%;
  height: 1px;

  background: rgba(255, 255, 255, 0.33);
  border: none;
  margin: 0.5rem 0 0.5rem 0;
}

.launchpad-footer-text {
  font-size: 0.75rem;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

/* Thanks to: https://codepen.io/Tcip/pen/LYXaxab */
.shake-like-nicki {
	animation: shake 500ms ease-out;
}

@keyframes shake {
	0% {
		transform: translateX(0);
	}
	5% {
		transform: translateX(-6px);
	}
	15% {
		transform: translateX(6px);
	}
	25% {
		transform: translateX(-6px);
	}
	35% {
		transform: translateX(6px);
	}
	45% {
		transform: translateX(-6px);
	}
	55% {
		transform: translateX(6px);
	}
	65% {
		transform: translateX(-6px);
	}
	75% {
		transform: translateX(4px);
	}
	85% {
		transform: translateX(-4px);
	}
	100% {
		transform: translateX(0);
	}
}

@media screen and (max-width: 768px) {
  .launchpad-signature {
    width: 8rem;
  }

  .launchpad-h1 {
    font-size: 1.25rem;
  }

  .launchpad-icon {
    width: 64px;
  }
}
