 @font-face {
  font-family: 'Hack';
  src: url('static/fonts/hack-regular.woff2?sha=3114f1256') format('woff2'), url('static/fonts/hack-regular.woff?sha=3114f1256') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Hack';
  src: url('static/fonts/hack-bold.woff2?sha=3114f1256') format('woff2'), url('static/fonts/hack-bold.woff?sha=3114f1256') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Hack';
  src: url('static/fonts/hack-italic.woff2?sha=3114f1256') format('woff2'), url('static/fonts/hack-italic.woff?sha=3114f1256') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Hack';
  src: url('static/fonts/hack-bolditalic.woff2?sha=3114f1256') format('woff2'), url('static/fonts/hack-bolditalic.woff?sha=3114f1256') format('woff');
  font-weight: 700;
  font-style: italic;
}

.App {
  height: 100vh;

  display: flex;
  flex-direction: column;

  font-family: "Hack";
}

h2 {
  color: #fefefe;
  opacity: 0.9;
}

@media screen and (max-device-width: 480px){
  body{
    -webkit-text-size-adjust: 100%;
  }
}
