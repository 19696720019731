.terminal {
  color: #fafafa;

  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 0.5rem;

  font-size: 1rem;
  font-weight: bold;
}

.terminal-canvas {
  position: absolute;
  top: 0;

  z-index: -1;

  background-repeat: no-repeat;

  opacity: 1;

  -webkit-transition: opacity 2.0s ease-in-out;
  transition: opacity 2.0s ease-in-out;
}

/* Decrease the font size for the terminal on smaller screens */
@media (max-width: 768px) {
  .terminal {
    font-size: 0.6rem;
    font-weight: bolder;
  }
}
